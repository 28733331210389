.cta {
	text-align: center;

	.section-inner {
		padding: 48px 16px;
	}

	.section-title {
		margin-bottom: 40px;
	}
}

.cta-inner {
	position: relative;
    background-color: rgb(255, 255, 255);
    border-color: transparent;
    border-radius: 2rem;
	box-shadow: rgba(0, 0, 0, 0.2) -3px 0px 15px -3px;
	border-radius: 20px;
	padding: 2px;
	display: flex;
	flex-direction: column;
	// &::before {
	// 	content: '';
	// 	position: absolute;
	// 	right: 98px;
	// 	top: -117px;
	// 	width: 160px;
	// 	height: 187px;
	// 	background-image: url('../../images/landingPage/cta-illustration.svg');
	// 	background-repeat: no-repeat;
	// }

	> * {
		position: relative; /* To display elements above hero illustrations */
	}
}

@include media( '>medium') {

	.cta {
		text-align: left;

		.section-inner {
			padding: 64px 32px;
		}

		.section-title {
			margin-bottom: 0;
			padding-right: 24px;
		}
	}

	.cta-inner {
		display: flex;
		// align-items: center;
		// justify-content: space-between;
	}

	.cta-input {
		margin-top: 15px;
		background-color: #FAFAFD;
		font-size: 15px;
		border: 1px solid #E5ECF5;
		padding: 16px 18px;
		width: 100%;
	}
}
