.button {
    display: inline-flex;
    @include font-size(8, mobile, true, false, true);
    @if ( get-font-size(8, desktop) != get-font-size(8, mobile) ) {
        @include media( '>medium' ) {
            @include font-size(8, desktop, true, false, true);
        }
    }
    @include font-weight(semibold);
    line-height: 16px;
    text-decoration: none !important;
	text-transform: uppercase;
    background-color: color(primary, 1);
    color: color(typography, 1) !important;
    border: none;
    border-radius: 2px;
    cursor: pointer;
    justify-content: center;
    padding: 16px 32px;
	height: 48px;
    text-align: center;
    white-space: nowrap;

	&:hover {
		background: lighten(color(primary, 3), 1%);
	}

    &:active {
        outline: 0;
    }

    &::before {
        border-radius: 2px;
    }
}

.button-sm {
    padding: 8px 24px;
	height: 32px;
}

.button-primary {
	background: mix(color(primary, 1), color(primary, 2));
    background: linear-gradient(65deg, color(primary, 1) 0, color(primary, 2) 100%);

    &:hover {
		background: lighten(mix(color(primary, 1), color(primary, 2)), 2%);
        background: linear-gradient(65deg, lighten(color(primary, 1), 2%) 0, lighten(color(primary, 2), 2%) 100%);
    }
}

.button-block {
    display: flex;
}

.button-block {
    display: flex;
	width: 100%;
}

@include media( '<=medium' ) {

	.button-wide-mobile {
		width: 100%;
		max-width: 280px;
	}
}
