.pcoded-navbar:not(.theme-horizontal) .navbar-content {
  height: calc(100% - 70px);
}

.card {
  .card-header+.collapse {
    >.card-body {
      padding-top: 0;
    }
  }
}

.pcoded-navbar {
  .pcoded-inner-navbar {
    li {
      >a.active {
        color: #e5efff;
        /*font-weight: 600;*/
      }


      .pcoded-submenu>li {

        &.active,
        &:focus,
        &:hover {

          >a,
          >a.active {
            &:before {
              background: #e5efff;
            }
          }
        }

        >a.active {
          &:before {
            background: #e5efff;
          }
        }
      }
    }

    >li {

      &.active,
      &.pcoded-trigger {

        >a,
        >a.active {
          color: #e5efff;
        }
      }

    }

    >.pcoded-menu-caption {

      &.active,
      &.pcoded-trigger {
        &:after {
          display: none;
        }
      }
    }
  }

  &.menu-light {
    .pcoded-inner-navbar {
      li {
        >a.active {
          color: $primary-color;
          background: transparent;

          &:before {
            background: $primary-color;
          }
        }

        .pcoded-submenu>li {

          >a.active {
            &:before {
              background: $primary-color;
            }
          }
        }

      }
    }
  }
}

.search-bar {
  display: block;
}

table.dataTable.table-condensed .sorting:after,
table.dataTable.table-condensed .sorting_asc:after,
table.dataTable.table-condensed .sorting_desc:after {
  top: 16px;
}

table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:after {
  font-family: feather, serif !important;
  content: "\e82a";
}

table.dataTable thead .sorting_asc:after {
  content: "\e82d";
}

table.dataTable thead .sorting_desc:after {
  content: "\e82a";
}

.css-1bm1z97-ToastContainer {
  top: 70px !important;

  .alert-inverse .close {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.pcoded-navbar.menu-light.theme-horizontal .pcoded-inner-navbar>li.pcoded-hasmenu.active:before,
.pcoded-navbar.menu-light.theme-horizontal .pcoded-inner-navbar>li.pcoded-hasmenu.pcoded-trigger:before {
  color: transparent;
  text-shadow: none;
}

.w-full {
  width: 100%;
}

.input-group {
  .rdtPicker {
    width: auto !important;
  }
}

.rating span {
  width: 100px;
}

.pac-container {
  z-index: 10000;
}

.header-logo-image {
  width: 100px;
}

.form-control {
  appearance: auto !important;
}

.abbr-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 220px;
}

.abbr-text::after {
  content: "\2026";
  /* Add three dots to end of content */
  display: inline-block;
  /* Display dots on the same line */
  width: 1em;
  /* Set width of dots to prevent wrapping */
  overflow: hidden;
  /* Hide any extra space */
  vertical-align: bottom;
  /* Align dots with baseline of text */
}

.relative {
  position: relative;
}

.calendar-icon {
  position: absolute;
  right: 22px;
  bottom: 14px;
}

.dropzone.filepicker {
  border-style: dashed !important;
  border-color: #4886FF!important;
  border-width: 2px;
  background-color: #e2edfe;
  text-align: center;
  padding: 30px;
  min-height: 60px;
  border-radius: 4px;
}

/* Icon */

.filepicker-file-icon {
  position: relative;
  display: none;
  margin: 1.5em 0 2.5em 0;
  padding-left: 45px;
  color: #646464;

  &::before {
    position: absolute;
    top: -7px;
    left: 0;
    width: 29px;
    height: 34px;
    content: '';
    border: solid 2px #646464;
    border-radius: 2px;
  }

  &::after {
    font-size: 11px;
    line-height: 1.3;
    position: absolute;
    top: 9px;
    left: -4px;
    padding: 0 2px;
    content: 'file';
    content: attr(data-filetype);
    text-align: right;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #fff;
    background-color: #646464;
  }

  .fileCorner {
    position: absolute;
    top: -7px;
    left: 22px;
    width: 0;
    height: 0;
    border-width: 11px 0 0 11px;
    border-style: solid;
    border-color: white transparent transparent #920035;
  }
}

.dropzone {
  .dz-image {
    img {
      display: inline-block;
    }
  }

  .dz-message {
    margin: 0;
  }

  .dz-preview {
    &.dz-image-preview {
      background: transparent;
    }

    &.dz-error {

      .dz-error-message,
      .dz-error-mark,
      .dz-progress {
        display: none;
      }
    }

    .dz-success-mark {
      opacity: 0.6;
      display: none;
    }

    &:hover {
      .dz-success-mark {
        opacity: 0;
      }
    }
  }
}

.category {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0 16px;
}

.category-title {
  font-weight: 600;
  color: #676767;
  font-size: 12px;
}

.category-description {
  font-weight: 600;
  color: #232323;
  font-size: 16px;
}

.wrapper {
  // text-transform: uppercase;
  // background: #ececec;
  // color: #555;
  // cursor: help;
  // font-family: "Gill Sans", Impact, sans-serif;
  font-size: 20px;
  // margin: 100px 75px 10px 75px;
  // padding: 15px 20px;
  position: relative;
  // text-align: center;
  // width: 200px;
  -webkit-transform: translateZ(0); /* webkit flicker fix */
  -webkit-font-smoothing: antialiased; /* webkit text rendering fix */
  }
  
  .wrapper .tooltip {
  background: #1496bb;
  bottom: 100%;
  color: #fff;
  display: block;
  left: -20px;
  margin-bottom: 15px;
  opacity: 0;
  padding: 10px;
  pointer-events: none;
  position: absolute;
  width: 100%;
  -webkit-transform: translateY(10px);
    -moz-transform: translateY(10px);
    -ms-transform: translateY(10px);
     -o-transform: translateY(10px);
      transform: translateY(10px);
  -webkit-transition: all .25s ease-out;
    -moz-transition: all .25s ease-out;
    -ms-transition: all .25s ease-out;
     -o-transition: all .25s ease-out;
      transition: all .25s ease-out;
  -webkit-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
    -moz-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
    -ms-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
     -o-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
      box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  }
  
  /* This bridges the gap so you can mouse into the tooltip without it disappearing */
  .wrapper .tooltip:before {
  bottom: -20px;
  content: " ";
  display: block;
  height: 20px;
  left: 0;
  position: absolute;
  width: 100%;
  }
  
  /* CSS Triangles - see Trevor's post */
  .wrapper .tooltip:after {
  border-left: solid transparent 10px;
  border-right: solid transparent 10px;
  border-top: solid #1496bb 10px;
  bottom: -10px;
  content: " ";
  height: 0;
  left: 50%;
  margin-left: -13px;
  position: absolute;
  width: 0;
  }
  
  .wrapper:hover .tooltip {
  opacity: 1;
  pointer-events: auto;
  -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
     -o-transform: translateY(0px);
      transform: translateY(0px);
  }
  
  /* IE can just show/hide with no transition */
  .lte8 .wrapper .tooltip {
  display: none;
  }
  
  .lte8 .wrapper:hover .tooltip {
  display: block;
  }
  
.home-board {
  background-color: #fbfaff;
  display: flex;
  justify-content: flex-start;
  margin-top: 12px;
}

.home-board-icon {
  width: 40px;
}

.text-primary {
  color: #3D3067!important
}

.text-success {
  color: #006736
}
.text-24 {
  font-size: 24px;
}
.bg-danger {
  background: #ED6A68;
}
.bg-success1 {
  background: #006736;
}
.bg-success1:hover {
  background: #006736;
}

.border-danger {
  border: 1px solid #ED6A68;
  color: #ED6A68!important;
  background-color: transparent;
}
.border-danger:hover {
  background-color: transparent;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.vy-button {
  border-radius: 6px;
  // width: 200px;
  height: 54px;
  font-size: 16px;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
}

.w-100p {
  width: 100px;
}

.ask-section{
  background: #F9F9FC; 
  padding: 22px 22px; 
  font-size: 22px;
  margin-top: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.main-title {
  color: #3D3067!important;
  font-size: 38px;
  font-weight: 700;
  margin-bottom: 10px;
  margin-top: 10px;
  text-align: center;
}

.bold {
  font-weight: 600;
}

.img-title {
  font-size: 34px;
  font-weight: 600;
  color: #3D3067;
  max-width: 500px;
  line-height: 40px;
}

.btn-disabled {
  cursor: no-drop;
  opacity: 0.7;
}

.version {
  position: fixed;
  bottom: 3px;
  left: 3px
}